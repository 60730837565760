import { createRouter, createWebHistory } from 'vue-router'
import indexView from '../views/index.vue'
import iosView from '../views/ios.vue'



const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView
  },{
    path: '/ios',
    name: 'ios',
    component: iosView
  }
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
